<template>
  <v-card
    height="100%"
    dark
    raised
    class="mx-auto flexcard neu-glow w-100 h-100"
  >
    <v-card-text class="h-100">
      <div :id="this.id" class="h-100 w-100 neu-glow"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { TimeserieComponent } from "polywag";

export default {
  name: "DashCard",
  props: {
    id: String,
    device_id: String
  },
  components: {},
  data() {
    return {
      chartComponent: null
    };
  },
  computed: {
    ...mapState({
      timestamp(state) {
        return state[this.device_id]["timestamp"];
      },
      data(state) {
        return state[this.device_id][this.id];
      }
    })
  },
  mounted: function () {
    this.chartComponent = new TimeserieComponent(this.id, {
      title: this.id,
      color: "rgba(253, 231, 37, 0.7)"
    });
  },
  watch: {
    timestamp(newValue) {
      let data = {
        timestamp: newValue,
        value: this.data
      };
      this.chartComponent.update(data);
    }
  }
};
</script>

<style scoped></style>
