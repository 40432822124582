<template>
  <ditto-dynamic-layout :layout="layout" theme="mobile-theme">
    <template v-slot="{ pane }">
      <div
        class="d-flex align-center h-100 w-100"
        :ref="pane.id"
        :style="{ background: 'black' }"
      >
        <DashCard :id="pane.id" :device_id="device_id" />
      </div>
    </template>
  </ditto-dynamic-layout>
</template>

<script>
import { component as DittoDynamicLayout } from "@/../ditto/dynamicLayout";
import DashCard from "@/components/DashCard.vue";
import { mapState } from "vuex";
import { extractData } from "@/common/api.queries";
import { groupBy, map } from "lodash";

export default {
  name: "Dashboard",
  props: {},
  components: { DittoDynamicLayout, DashCard },
  data() {
    return {
      layout: {
        horizontal: true,
        name: "2x2",
        panes: [
          {
            panes: [
              {
                id: "temperature",
                width: 50,
                height: 50
              },
              {
                id: "humidity",
                width: 50,
                height: 50
              }
            ]
          },
          {
            panes: [
              {
                id: "co2",
                width: 50,
                height: 50
              },
              {
                id: "voc",
                width: 50,
                height: 50
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      device_id(state) {
        return state.activeDevice;
      }
    })
  },
  mounted: function () {
    let to = new Date();
    let from = new Date();
    from.setMinutes(from.getMinutes() - 30);
    let data = {
      date_from: from.toISOString(),
      date_to: to.toISOString(),
      subsample: 0,
      tags: [],
      voc: 1,
      co2: 1,
      temperature: 1,
      humidity: 1
    };
    console.log("EXTRACT DATA FOR DASHBOARD");
    extractData(data).then(resp => {
      let measurements = groupBy(resp.body, "device_id");
      console.log(measurements);
      for (let device_id in measurements) {
        let data = {};
        data["timestamp"] = map(measurements[device_id], "timestamp");
        data["temperature"] = map(measurements[device_id], "temperature");
        data["humidity"] = map(measurements[device_id], "humidity");
        data["co2"] = map(measurements[device_id], "co2");
        data["voc"] = map(measurements[device_id], "voc");
        this.$store.dispatch("initMeasurement", [device_id, data]);
      }
    });
  }
};
</script>

<style scoped>
.black-background {
  background-color: black;
}
</style>
